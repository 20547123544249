import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Worker, Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/despachos-de-cobranza",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};
export const DespachosDeCobranza = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-4 p-4 max-w-4xl">
        <div className="flex flex-col gap-4 py-4">
          {/* <DemoButton callback={fillDemo} /> */}
           <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl="/docs/despachos.pdf"
                    className="min-w-[800px] w-[800px]"
            />
           </Worker>
        </div>
      </div>
    </div>
  );
};
