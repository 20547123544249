import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/informacion-regulatoria",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

export const RegulatoryInfo = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Información Regulatoria de Vivus México </title>
        <meta name="title" content="Información Regulatoria de Vivus México" />
        <meta name="description" content="DIFINANCE es una entidad perteneciente al sistema financiero mexicano. Se encuentra debidamente regulada por la CONDUSEF y por la CNBV." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <div className="flex flex-col gap-4 py-4">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <div className="flex w-full justify-between">
                <img className="w-60 pb-8" src={"/img/navbar/logo-vivus.svg"} alt="vivus logo" />
                <img className="w-60 pb-8" src={"/img/amfe.png"} alt="AMFE Logo" />
              </div>
              <h1 className="text-2xl text-green-dark font-bold">Información regulatoria</h1>
              <p className="pb-4">
                <strong>Difinance, S.A. de C.V., SOFOM, E.N.R. (“DIFINANCE”)</strong> es una entidad perteneciente al sistema financiero mexicano. Se encuentra debidamente regulada por la{" "}
                <strong>Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (“CONDUSEF”)</strong> y por la <strong>Comisión Nacional Bancaria y de Valores (“CNBV”)</strong>, por lo que hace a cuestiones relacionadas con la prevención de lavado de dinero y combate
                al financiamiento al terrorismo. Asimismo, <strong>DIFINANCE</strong> es miembro de la <strong>Asociación Mexicana de Entidades Financieras Especializadas (“AMFE”)</strong>, cuya misión es la de favorecer entre las asociadas y autoridades un entorno de dialogo, comunicación y
                divulgación de las mejores prácticas en materia de crédito especializado.
              </p>
              <p className="pb-4">
                Puedes verificar que nuestro registro ante CONDUSEF se encuentra vigente en la siguiente página de internet:{" "}
                <a href="https://www.buro.gob.mx" target="_blank" className="text-green-light underline">
                  https://www.buro.gob.mx
                </a>
              </p>
              <p className="pb-4">Los servicios financieros que brindamos se encuentran debidamente registrados y regulados por la CONDUSEF. Son transparentes para todos nuestros clientes y seguros. </p>
              <p className="pb-4">
                <strong>DIFINANCE</strong> es titular de las marcas “VIVUS” y “VIVUS CARD” y nuestras únicas páginas oficiales son:{" "}
                <a href="https://vivus.com.mx/" target="_blank" className="text-green-light underline">
                  https://vivus.com.mx/
                </a>{" "}
                y{" "}
                <a href="https://www.vivuscard.com.mx/" target="_blank" className="text-green-light underline">
                  https://www.vivuscard.com.mx/
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
