import { useState, useEffect } from "react";
import { remoteConfig } from "../App";
import { fetchAndActivate, getValue } from "firebase/remote-config";

const API_BALANCER_URL = "https://balancer.vivusmx.com/proxy";
const BRAND_ID = "SMSFINANCE";
const BALANCER_API_KEY = "superSecret666key!";

export const useBalancerApi = () => {
  const [isCandidate, setIsCandidate] = useState(false);
  const [tresholdNew, setTresholdNew] = useState(100);
  const [tresholdOld, setTresholdOld] = useState(100);

  const getAnalyticsQueryParams = () => {
    for (let i = 0; i <= window.dataLayer.length - 1; i++) {
      if (window.dataLayer[i].event === "gl_ready") {
        return `_gl=${window.dataLayer[i].gtagApiResult._gl}`; //Значение переменной
      }
    }
    return "";
  };

  /**
   * Get values from Firebase Remote Config and set the tresholds
   */
  useEffect(() => {
    const fetchTresholdData = async () => {
      await fetchAndActivate(remoteConfig);

      const tNew = getValue(remoteConfig, "balancer_treshold_new");
      const tOld = getValue(remoteConfig, "balancer_treshold_old");

      // Check if tNew is between 0 and 100, is an integer and is not null
      if (tNew.asNumber() >= 0 && tNew.asNumber() <= 100 && Number.isInteger(tNew.asNumber())) {
        setTresholdNew(tNew.asNumber());
      }
      if (tOld.asNumber() >= 0 && tOld.asNumber() <= 100 && Number.isInteger(tOld.asNumber())) {
        setTresholdOld(tOld.asNumber());
      }
    };
    fetchTresholdData();
  }, []);

  /**
   * Function to determine if a user exist on the new API
   * @param {*} email
   * @returns boolean
   */
  const checkExistence = async (email) => {
    return false;
    try {
      const res = await fetch(`${API_BALANCER_URL}/check?email=${email}&brand=${BRAND_ID}`, {
        method: "GET",
        headers: {
          "x-api-key": BALANCER_API_KEY,
        },
      });
      if (res.status === 200) {
        const is = (await res.text()) == "true" ? true : false;
        setIsCandidate(is);
        return is;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    return false;
  };

  /**
   * Function to store the candidate on the new API and returns the URL the user will be redirected
   * @param {string} clientId
   * @param {string} email
   * @param {string} password
   * @returns string
   */
  const storeData = async (clientId, email, password) => {
    return false;
    try {
      const obj = {
        email,
        brand: BRAND_ID,
      };

      obj["clientId"] = clientId;
      obj["password"] = password;
      const response = await fetch(`${API_BALANCER_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": BALANCER_API_KEY,
        },
        body: JSON.stringify(obj),
      });

      const queryParams = getAnalyticsQueryParams();
      const code = response.status;
      if (code !== 200) {
        return null;
      }
      let link = await response.text();

      //Detect if link contains query params
      let resLink = "";
      if (link.includes("?")) {
        resLink = `${link}&${queryParams}`;
      } else {
        // If link does not end with a slash, add it
        resLink = `${link}?${queryParams}`;
      }

      return `${resLink}`;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  /**
   * Based on the TRESHOLD_BALANCER, this function will determine if the user should be redirected to the new API.
   * This operation only applies on repeater users.
   * @returns boolean
   */
  const balancer = (newUsers) => {
    // Calculate the probability of the user to be redirected, based on the TRESHOLD_BALANCER / 100.
    const probability = Math.random() * 100;
    const treshold = newUsers ? tresholdNew : tresholdOld;
    setIsCandidate(probability <= treshold);
    return probability <= treshold;
  };

  return { isCandidate, checkExistence, storeData, balancer };
};
