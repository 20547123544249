import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faComment } from "@fortawesome/free-solid-svg-icons";
import "./popupFooter.css";
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok } from "@fortawesome/free-brands-svg-icons";

const Footer = ({ location }) => {
  const [popup, setPopup] = useState(false);
  const supportPerson = process.env.REACT_APP_SITE === "mivivus" ? "/img/footer/support-mivivus.png" : "/img/footer/support.webp";
  return (
    <>
      <section className="text-white flex flex-col">
        <div className="bg-green-light w-full flex justify-center relative">
          <div className="container justify-between flex flex-col-reverse lg:flex-row p-4 relative">
            <div className="h-10 flex absolute -top-10 right-4 bg-gray-800 rounded-t-xl px-8 ">
              <ul className="flex gap-8 text-xl items-center h-full">
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/VivusMexico/">
                    <FontAwesomeIcon className="clickable" icon={faFacebookF} />
                  </a>
                </li>

                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/vivusmx/">
                    <FontAwesomeIcon className="clickable" icon={faInstagram} />
                  </a>
                </li>

                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@vivusmexico">
                    <FontAwesomeIcon className="clickable" icon={faTiktok} />
                  </a>
                </li>

                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/vivus-mexico/">
                    <FontAwesomeIcon className="clickable" icon={faLinkedinIn} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col-reverse items-center gap-4 lg:flex-row">
              <img src={supportPerson} alt="customer service" className="-mb-4 w-40" />
              <div className="flex flex-col gap-2 lg:text-left items-center text-center lg:items-start justify-center">
                <h3 className="text-2xl">
                  <strong>¿Necesitas ayuda?</strong>
                </h3>
                <p className="text-lg">Contáctanos</p>
                <p className="text-md">Por favor, si tienes alguna duda, ponte en contacto con nosotros.</p>
              </div>
            </div>
            <div className="flex flex-col py-12 lg:py-0 items-center lg:items-end justify-center gap-4">
              <div className="flex gap-4">
                <div className="flex flex-col items-center gap-2">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faPhone} />
                  <a href="tel:5567170750">(55) 6717 0750</a>
                </div>
                <a href="mailto:info@vivus.com.mx" className="flex flex-col items-center gap-2">
                  <FontAwesomeIcon className="cursor-pointer text-2xl" icon={faEnvelope} />
                  <span>info@vivus.com.mx</span>
                </a>
              </div>
              <div className="flex flex-col items-center text-center lg:text-left lg:items-end">
                <p>
                  <strong>Nuestro horario de atención al cliente es:</strong>
                </p>
                <p>Lunes a Viernes de 8:00 a 20:00 /Sábado de 8:00 a 14:00</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="container px-4 py-12 gap-12  justify-center items-center lg:justify-start lg:items-start flex flex-col lg:flex-row lg:py-8">
            <div className="flex flex-col lg:flex-row gap-12 items-center">
              <ul className="w-full self-start flex flex-col gap-2 justify-center items-center lg:justify-start lg:items-start">
                <li>
                  <Link to={location === "manteinance" ? "#" : "/"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos en línea
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-en-linea-rapidos"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos en línea rápidos
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-en-linea-urgentes"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos en línea urgentes
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-personales"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos Personales
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-confiables"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos Confiables
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-seguros-y-confiables"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos seguros y confiables
                  </Link>
                </li>
              </ul>
              <ul className="w-full self-start flex flex-col gap-2 justify-center items-center lg:justify-start lg:items-start">
                <li>
                  <Link to={location === "manteinance" ? "#" : "/app-de-prestamos-confiables"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    APP de préstamos confiables
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-express"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos express
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-sin-buro-de-credito"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos sin buró de crédito
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/minicreditos"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Minicréditos
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/microcreditos"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Microcréditos
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/prestamos-seguros"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Préstamos seguros
                  </Link>
                </li>
              </ul>

              <ul className="w-full self-start flex flex-col gap-2 justify-center items-center lg:justify-start lg:items-start">
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/como-funciona">
                    ¿Cómo funciona?
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/sobre-nosotros">
                    Sobre nosotros
                  </Link>
                </li>
                <li>
                  <a className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" href="https://blog.vivus.com.mx/">
                    Blog
                  </a>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/contenido/aviso-de-privacidad"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Aviso de privacidad
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/contenido/terminos-y-condiciones"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Términos y Condiciones
                  </Link>
                </li>
                <li>
                  <Link to={location === "manteinance" ? "#" : "/contenido/despachos-de-cobranza"} className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start">
                    Despachos de cobranza
                  </Link>
                </li>
              </ul>
              <ul className="w-full self-start flex flex-col gap-2 justify-center items-center lg:justify-start lg:items-start">
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/historial-crediticio">
                    Historial crediticio
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/creditos-en-linea">
                    Créditos en línea
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/dinero-rapido">
                    Dinero rápido
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamo-inmediato">
                    Préstamos inmediatos en línea
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-cdmx">
                    Préstamos en CDMX
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-aguascalientes">
                    Préstamos en Aguascalientes
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-tijuana">
                    Préstamos en Tijuana
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-puebla">
                    Préstamos en Puebla
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-morelia">
                    Préstamos en Morelia
                  </Link>
                </li>
                <li>
                  <Link className="text-gray-500 underline hover:text-gray-800 transition-all text-center lg:text-start" to="/prestamos-en-queretaro">
                    Préstamos en Querétaro
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex-1"></div>
            <div className="flex flex-col lg:flex-row gap-4 items-center lg:self-start">
              <a href="https://play.google.com/store/apps/details?id=mx.app.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img alt="Disponible en Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png" width={180} />
              </a>
              <a href="https://www.condusef.gob.mx/" target="_blank">
                <img width="90px" src="/img/condusef.jpeg" alt="buro" />
              </a>
              <a href="https://www.buro.gob.mx/" target="_blank">
                <img width="50px" src="/img/logo-buro.png" alt="buro" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <a className="fixed bottom-4 right-4 z-50 rounded-full h-16 w-16 bg-green-light hover:bg-green-dark transition-all flex items-center justify-center p-4" rel="noopener noreferrer" target="_blank" href="https://app-digital.mitrol.cloud/vivuswebchat/index.html?serviceRoute=ChatVivu1">
        <FontAwesomeIcon className="text-white text-2xl" icon={faComment} />
      </a>
    </>
  );
};

export default Footer;
