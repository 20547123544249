import React, { useState, useEffect, lazy, Suspense } from "react";
import { getToken, saveSingleUTM } from "../../services/api.js";
import TagManager from "react-gtm-module";
import "./landing.scss";
import { momentEs } from "../../services/moment";
import { Helmet } from "react-helmet";
import Cover from "./home/Cover";
const OnlineLoans = lazy(() => import("./home/OnlineLoans"));
const Reasons = lazy(() => import("./home/Reasons"));
const Legal = lazy(() => import("./home/Legal"));
const HomeBlog = lazy(() => import("./home/HomeBlog"));
const CallToAction = lazy(() => import("./home/CallToAction"));

const sendUTM = async (utm) => {
  const res = await getToken();
  if (!res) return;
  const { token } = res?.data;

  saveSingleUTM(
    {
      customerOrigin: document.URL,
      utm: utm != "/" ? utm : "organic",
      customerId: 0,
      productId: 1,
      userAgent: navigator.userAgent,
      clientIP: "0.0.0.0",
    },
    token
  ).then((res) => {});
};

const Home = (props) => {
  const [, setTempScreen] = useState(null);

  useEffect(() => {
    if (props.location.search === "") sessionStorage.setItem("utm", "/");
    else sessionStorage.setItem("utm", props.location.search);
    const tagManagerArgs = {
      dataLayer: {
        event: "pageChange",
        page: {
          url: "/",
          referrer: sessionStorage.getItem("utm") || "/",
        },
      },
      dataLayerName: "dataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
    if (!sessionStorage.getItem("utm-sent")) {
      sendUTM(sessionStorage.getItem("utm"));
    }
  }, [props.location.search]);

  useEffect(() => {
    let startDate = momentEs("01/04/2020", "DD/MM/YYYY");
    let endDate = momentEs("30/04/2020", "DD/MM/YYYY");
    if (momentEs().isBetween(startDate, endDate)) return setTempScreen(true);
    return setTempScreen(false);
  }, []);

  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>VIVUS | Préstamos en línea</title>
        <meta name="title" content="VIVUS | Préstamos en línea" />
        <meta name="description" content="Préstamos Personales Vivus México. Rápido, fácil y sin tanto papeleo. 100% en línea. Comienza la solicitud de tu crédito en línea aquí." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="w-screen h-auto flex flex-col">
        <Cover />
        <Reasons />
        <OnlineLoans />
        <CallToAction />
        <HomeBlog />
        <Legal />
      </div>
    </Suspense>
  );
};

export default Home;
