import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = [
  "¿Qué son los préstamos inmediatos en línea?",
  "¿Cómo puedo solicitar un préstamo inmediato en Vivus?",
  "¿Puedo obtener un préstamo inmediato si estoy en buró de crédito?",
  "¿Qué monto puedo solicitar en un préstamo inmediato?",
  "¿Qué tan seguros son los préstamos en línea de Vivus?",
  "¿Cuánto tiempo tarda en aprobarse mi solicitud?",
  "¿Qué puedo hacer si necesito más tiempo para pagar mi préstamo?",
  "¿Cuál es la diferencia entre un préstamo urgente y un préstamo inmediato?",
];

const answers = [
  "Son préstamos que se procesan y desembolsan rápidamente a través de plataformas en línea, permitiendo obtener el dinero necesario en cuestión de minutos.",
  "Debes registrarte en nuestro sitio web, completar el formulario de solicitud con tus datos personales y financieros, seleccionar el monto y plazo de pago, y esperar la aprobación.",
  "Sí, en Vivus ofrecemos préstamos en línea sin buró de crédito, evaluando tu capacidad actual de pago.",
  "Puedes solicitar desde pequeños montos hasta préstamos de 12 mil pesos, dependiendo de tus necesidades y capacidad de pago.",
  "Nuestros préstamos en línea son seguros y confiables, utilizando tecnología avanzada de encriptación para proteger tu información.",
  "Las solicitudes de préstamos inmediatos se aprueban en pocos minutos, permitiéndote acceder al dinero rápidamente.",
  "Puedes solicitar una extensión del plazo de pago a través de nuestra plataforma en línea o contactando a nuestro equipo de atención al cliente.",
  "Un préstamo urgente es una necesidad de financiamiento que requiere resolución rápida, mientras que un préstamo inmediato se refiere a la capacidad de obtener el préstamo en minutos, generalmente a través de plataformas en línea como Vivus.",
];

const PrestamosInmediatos = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Obtén dinero en minutos con préstamos inmediatos en línea | Vivus Mx</title>
        <meta name="title" content="Obtén dinero en minutos con préstamos inmediatos en línea | Vivus Mx" />
        <meta name="description" content="Obtén préstamos inmediatos en línea con Vivus. Soluciones rápidas y confiables sin buró de crédito. Préstamos urgentes de hasta 12 mil pesos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO title="Obtén Préstamos Inmediatos con Vivus: Rápidos y Confiables" description="Obtén préstamos inmediatos en línea con Vivus. Soluciones rápidas y confiables sin buró de crédito. Préstamos urgentes de hasta 12 mil pesos." />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_prestamos_inmediatos.jpg`} alt="préstamos inmediatos" className="w-full object-cover" />
          <p>Las deudas no esperan. Por eso, ofrecemos préstamos inmediatos en línea, diseñados para que tengas el dinero que necesitas de manera rápida y sin complicaciones.</p>

          <h2 className="text-xl font-bold">¿Cómo solicitar un préstamo inmediato?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          <h2 className="text-xl font-bold">¿Por Qué Elegir Préstamos Inmediatos en Línea?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Velocidad y Eficiencia</b>

              <ul className="list-disc pl-5">
                <li>Con Vivus, obtén préstamos urgentes en minutos.</li>
                <li>Olvídate de largas esperas; nuestro proceso es 100% en línea.</li>
              </ul>
            </li>
            <li>
              <b className="font-bold">Flexibilidad y montos</b>

              <ul className="list-disc pl-5">
                <li>
                  Solicitamos desde pequeños préstamos hasta <b className="font-bold">préstamos de 12 mil pesos</b>, ajustados a tus necesidades.
                </li>
                <li>Ofrecemos plazos flexibles que se adaptan a tu situación financiera.</li>
              </ul>
            </li>
            <li>
              <b className="font-bold">Sin Buró de Crédito</b>

              <ul className="list-disc pl-5">
                <li>
                  ¿Mal historial crediticio? No hay problema. Ofrecemos{" "}
                  <a href="https://www.vivus.com.mx/" className="underline text-green-dark">
                    préstamos en línea
                  </a>{" "}
                  sin buró de crédito.
                </li>
                <li>Olvídate de largas esperas; nuestro proceso es 100% en línea.</li>
              </ul>
            </li>
            <li>
              <b className="font-bold">Seguridad y Confiabilidad</b>

              <ul className="list-disc pl-5">
                <li>Priorizamos tu seguridad con tecnología avanzada de encriptación.</li>
                <li>Nuestros préstamos en línea seguros y confiables te brindan la tranquilidad que necesitas.</li>
              </ul>
            </li>
          </ol>

          <h2 className="text-xl font-bold">Beneficios de Solicitar Préstamos Inmediatos con Vivus</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Préstamos confiables</b>: Miles de clientes satisfechos avalan nuestra confiabilidad.
            </li>
            <li>
              <b className="font-bold">Préstamos en línea rápidos</b>: Obtén una respuesta inmediata a tu solicitud.
            </li>
            <li>
              <b className="font-bold">Préstamos express</b>: Perfectos para cubrir gastos urgentes sin demoras.
            </li>{" "}
            <li>
              <b className="font-bold">Préstamos en línea</b>: Realiza todo el proceso desde la comodidad de tu hogar, sin papeleos innecesarios.
            </li>
          </ul>

          <h2 className="text-xl font-bold">Únete a Miles de Usuarios Satisfechos</h2>

          <p>Vivus se ha convertido en la opción preferida para aquellos que buscan préstamos inmediatos en línea debido a nuestra transparencia, rapidez y seguridad. No esperes más y resuelve tus urgencias financieras con nosotros.</p>

          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href={"https://www.vivus.com.mx/registration"}>
            Solicita tu préstamo inmediato
          </a>
          <p>Vivus, tu solución confiable y rápida para obtener el financiamiento que necesitas, justo cuando lo necesitas.</p>
          {/* <img src={`/img/seo/seo_prestamos_inmediatos_2.jpg`} alt="préstamos en ciudad de méxico" className="w-full object-cover" /> */}

          <h2 className="text-xl font-bold">Seguridad y Confianza</h2>

          <p>
            En Vivus, la seguridad de tus datos es nuestra prioridad, por lo que utilizamos tecnología avanzada para proteger tu información personal. Somos uno de los que brindamos <b className="font-bold">préstamos en línea seguros y confiables</b>, asegurando que todo el proceso de solicitud sea
            seguro. Además, estamos regulados por la CONDUSEF (Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros), lo que garantiza que operamos bajo estrictas normativas financieras. Con más de 8 años de experiencia en el mercado, Vivus ha ganado la confianza
            de miles de mexicanos.
          </p>

          <FAQ questions={questions} answers={answers} />
        </div>
      </div>
    </Suspense>
  );
};

PrestamosInmediatos.propTypes = {};
PrestamosInmediatos.defaultValues = {};

export default PrestamosInmediatos;
