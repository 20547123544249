import React, { useState, useEffect, Suspense } from "react";
import "./App.css";
import { Route, Switch, withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FAQ } from "./components/works/FAQ";
import TiktokPixel from "tiktok-pixel";
import { Helmet } from "react-helmet";
import Routes from "./routes/Routes";
import NavBar from "./components/common/NavBar";
import GeneralPopup from "./components/common/GeneralPopup";
import Footer from "./components/common/Footer";
import Calculator from "./components/calculator/Calculator";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { refreshIp } from "./services/ip";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { DespachosDeCobranza } from "./components/common/footer-links/DespachosCobranza";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8_8plMFDqYFcyKRYjQ1wmphGXvOdkHEA",
  authDomain: "vivus-app.firebaseapp.com",
  projectId: "vivus-app",
  storageBucket: "vivus-app.appspot.com",
  messagingSenderId: "34357899240",
  appId: "1:34357899240:web:caeb5399f1c3a6b30dfe5d",
  measurementId: "G-YG10SJJFP0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

// const GeneralPopup = React.lazy(() => import("./components/common/GeneralPopup"));
const LegalContent = React.lazy(() => import("./components/landing/home/LegalContent"));
const GeneralContract = React.lazy(() => import("./components/common/GeneralContract"));
const AvisoDePrivacidad = React.lazy(() => import("./components/common/footer-links/AvisoDePrivacidad"));
const TerminosCondiciones = React.lazy(() => import("./components/common/footer-links/TerminosCondiciones"));
// const Footer = React.lazy(() => import("./components/common/Footer"));
// const NavBar = React.lazy(() => import("./components/common/NavBar"));
// const Routes = React.lazy(() => import("./routes/Routes"));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER,
  dataLayerName: "dataLayer",
};

const jsonLdData = {
  "@context": "https://schema.org",
  "@type": "LoanOrCredit",
  name: "VIVUS",
  description: "VIVUS es una plataforma financiera que ofrece préstamos en línea personales de manera rápida y sencilla.",
  url: "https://www.vivus.com.mx/",
  amount: {
    "@type": "MonetaryAmount",
    currency: "MXN",
    value: "12000",
  },
  currency: "MXN",
  gracePeriod: "P5D",
  loanRepaymentForm: {
    "@type": "RepaymentSpecification",
    loanPaymentAmount: {
      "@type": "MonetaryAmount",
      currency: "MXN",
    },
    loanPaymentFrequency: "monthly",
  },
  loanTerm: {
    "@type": "QuantitativeValue",
    unitCode: "MON",
  },
  loanType: "PersonalLoan",
  recourseLoan: true,
  renegotiableLoan: false,
  requiredCollateral: "No",
  annualPercentageRate: {
    "@type": "QuantitativeValue",
    value: " ",
    unitCode: "P1Y",
  },
  feesAndCommissionsSpecification: "https://www.vivus.com.mx/como-funciona",
  interestRate: {
    "@type": "QuantitativeValue",
    value: " ",
    unitCode: "P1M",
  },
  provider: {
    "@type": "Organization",
    name: "VIVUS",
    url: "https://www.vivus.com.mx",
    logo: "https://www.vivus.com.mx/img/navbar/logo-vivus-mexico.svg",
    sameAs: ["https://www.facebook.com/VivusMexico/", "https://www.tiktok.com/@vivusmexico", "https://www.instagram.com/vivusmx/"],
  },
  serviceType: "Préstamos Personales",
  areaServed: "MX",
};

TagManager.initialize(tagManagerArgs);
TiktokPixel.init("CGCT1KJC77U6LAATJ3O0");

function App(props) {
  const [location, setLocation] = useState(null);
  const [isLogged, setIsLogged] = useState(false);

  const [loaded] = useState(false);
  const { pushInstruction } = useMatomo();

  const l = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [l]);

  useEffect(() => {
    if (window.location.pathname === "/") setLocation("home");
    if (props.location.pathname.includes("/dashboard")) setLocation("dashboard");
    if (props.location.pathname.includes("/login")) setLocation("app");
    // if(props.location.pathname.includes('/blog')) setLocation('blog')
    if (props.location.pathname.includes("/registration")) setLocation("app");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/contenido")) setLocation("contenido");
    if (props.location.pathname.includes("/sobre-nosotros")) setLocation("us");
    if (props.location.pathname.includes("/denied")) setLocation("denied");
    if (props.location.pathname.includes("/en-mantenimiento")) setLocation("manteinance");
    if (sessionStorage.getItem("loggedUser")) setIsLogged(true);
    else setIsLogged(false);
  }, [props.location]);

  useEffect(() => {
    if (sessionStorage.loggedUser) {
      const { customerId } = JSON.parse(sessionStorage.loggedUser);
      pushInstruction("setUserId", customerId + "");
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    try {
      if (sessionStorage.balance) {
        const { idCredit } = JSON.parse(sessionStorage.balance);
        pushInstruction("setCustomVariable", 1, "LOAN_ID", idCredit, "visit");
      }
    } catch (error) {
      console.log(error);
    }
  }, [window?.location?.pathname]);

  // Detect network changes to update the IP address
  useEffect(() => {
    if ("connection" in navigator) {
      let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      connection.addEventListener("change", () => refreshIp());
    } else {
      console.log("La API de información de red no es soportada por este navegador.");
      window.addEventListener("online", () => refreshIp());
    }
  }, []);

  useEffect(() => {
    TiktokPixel.pageView();
  }, [window?.location?.pathname]);

  return (
    <Suspense fallback={<div/>}>
      <Helmet>
        <meta name="description" content="Los préstamos personales en Vivus México son fáciles y rápidos. Obtén tus préstamos en línea en 24 horas. Descubre cómo funciona aquí." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <Switch>
        {/* RUTAS EN mantenimiento */}
        {/* <Route exact path='/' component={Home}/> */}
        <Route exact path="/mobile/privacidad" component={AvisoDePrivacidad} />
        <Route exact path="/mobile/terminos-y-condiciones" component={TerminosCondiciones} />
        <Route exact path="/mobile/faq" component={FAQ} />
        <Route exact path="/mobile/contract" component={GeneralContract} />
        <Route exact path="/mobile/despachos-de-cobranza" component={DespachosDeCobranza} />
        <Route exact path="/contenido/legal" component={LegalContent} />
        <Route exact path="/contenido/calculadora" component={Calculator} />
        <Route
          path="/*"
          render={(props) => (
            <div className="App flex flex-col">
              {loaded ? (
                <div></div>
              ) : (
                <>
                  <>
                    <NavBar isLogged={isLogged} location={location} setLocation={setLocation} />
                    <Routes />
                    {/* <Social location={location} /> */}
                  </>
                  <Footer location={location} />
                  <GeneralPopup />
                </>
              )}
            </div>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default withRouter(App);
