import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Page3 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en línea seguros y confiables| Vivus Mx</title>
        <meta name="title" content="Préstamos en línea seguros y confiables| Vivus Mx" />
        <meta name="description" content="¡Mira qué fácil! Préstamos en línea seguros y confiables. No pospongas más tus planes, entra en nuestra plataforma y solicítalo HOY mismo." />
        <meta name="keywords" content="préstamos en línea confiables,préstamos en línea seguros,préstamos en línea seguros y confiables" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Préstamos en línea seguros y confiables</h1>
          <p>En Vivus, te ofrecemos préstamos en línea seguros y confiables para cubrir tus necesidades financieras de manera rápida y eficiente. Nuestro compromiso es brindarte una experiencia transparente en cada etapa del proceso.</p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-2xl font-bold">¿Los préstamos en línea de Vivus son seguros?</h2>
          <p>
            Los préstamos en línea seguros de Vivus son una opción financiera confiable y conveniente que te permite obtener el dinero que necesitas de forma rápida, fácil y sin riesgos. Nuestro objetivo es proporcionarte un servicio transparente y proteger la seguridad de tus datos personales y
            financieros en todo momento.
          </p>
          <p>
            Con nuestros préstamos en línea, puedes solicitar un monto entre 2000- 12000 MX. Gracias a nuestro proceso de evaluación eficiente, obtendrás una decisión en poco tiempo. Además, te ofrecemos un entorno seguro para realizar tus transacciones en línea, utilizando tecnología de
            encriptación avanzada y protocolos de seguridad.
          </p>

          <h2 className="text-2xl font-bold">¿Cómo funcionan los préstamos en línea confiables?</h2>
          <p>
            En Vivus, entendemos la importancia de contar con un proceso confiable, sencillo y eficiente al solicitar préstamos en línea. Nuestro objetivo es brindarte una experiencia fluida y segura. Te explicamos cómo funcionan nuestros préstamos en línea:
            <ol className="list-decimal pl-5 space-y-2">
              <li>
                <b className="font-bold">Solicitud en línea:</b> Ingresa a nuestro sitio web y crea una cuenta gratuita. Completa el formulario de solicitud en línea con tus datos personales y financieros.
              </li>
              <li>
                <b className="font-bold">Evaluación y aprobación:</b> Nuestro equipo te contactará para corroborar tus datos y explicarte los detalles de tu préstamo.
              </li>
              <li>
                <b className="font-bold">Transferencia de fondos:</b> Una vez aprobada tu solicitud, recibirás el monto del préstamo directamente en tu cuenta bancaria. La velocidad de la transferencia puede variar según tu institución financiera. No obstante, nuestros clientes generalmente reciben
                el dinero en poco tiempo.
              </li>
              <li>
                <b className="font-bold">Pago del préstamo:</b> Durante el plazo acordado, deberás realizar el pago del préstamo, incluyendo los intereses correspondientes.
              </li>
            </ol>
          </p>

          <h2 className="text-2xl font-bold">¿Cómo solicitar un crédito con Vivus?</h2>
          <p>
            Para solicitar un crédito seguro en Vivus, simplemente sigue estos pasos:
            <ol className="list-decimal pl-5 space-y-2">
              <li>
                <b className="font-bold">Accede a nuestro sitio web</b> y crea una cuenta gratuita.
              </li>
              <li>
                <b className="font-bold">Completa el formulario de solicitud</b> en línea con tus datos personales y financieros.
              </li>
              <li>
                <b className="font-bold">Adjunta los documentos requeridos</b> como tu INE y el número de una cuenta bancaria a tu nombre.
              </li>
              <li>
                <b className="font-bold">Envía la solicitud</b> y espera la evaluación.
              </li>
              <li>
                <b className="font-bold">Una vez aprobado</b> recibirás el dinero en tu cuenta bancaria en poco tiempo.
              </li>
            </ol>
          </p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-2xl font-bold">Ventajas de los préstamos seguros en línea</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <b className="font-bold">RAPIDEZ:</b> Obtén el dinero que necesitas de forma rápida y sin demoras innecesarias. Nuestro proceso de solicitud y aprobación es ágil y eficiente, lo que te permite tener los fondos en tu cuenta en poco tiempo.
            </li>
            <li>
              <b className="font-bold">FLEXIBILIDAD:</b> Adaptamos nuestros préstamos a tus necesidades y capacidades financieras. Puedes seleccionar el monto y plazo que mejor se ajuste a tus requerimientos, brindándote la flexibilidad que necesitas.
            </li>
            <li>
              <b className="font-bold">SEGURIDAD:</b> En Vivus, la seguridad es nuestra prioridad. Utilizamos tecnología de encriptación y protocolos de seguridad avanzados para proteger tus datos personales y financieros, garantizando una experiencia segura y confiable en cada etapa del proceso.
            </li>
            <li>
              <b className="font-bold">CONVENIENCIA:</b> Nuestros préstamos en línea te brindan la comodidad de realizar todo el proceso desde la comodidad de tu hogar. Puedes solicitar el préstamo, enviar la documentación requerida y realizar los pagos a través de nuestra plataforma en línea, sin
              necesidad de desplazarte a una sucursal física.
            </li>
            <li>
              <b className="font-bold">TRANSPARENCIA:</b> En Vivus, te ofrecemos transparencia en todas nuestras operaciones. Te brindamos información clara y detallada sobre los costos asociados al préstamo, incluyendo tasas de interés y comisiones, para que puedas tomar decisiones financieras
              informadas.
            </li>
          </ul>

          <h2 className="text-2xl font-bold">¿Cuándo debo pagar mis créditos en línea?</h2>
          <p>Al solicitar tu crédito, acordaremos un plazo de tiempo para la devolución del dinero. Este puede variar según el monto del préstamo y tus preferencias. Te brindamos opciones de plazos que van desde 7-30 días.</p>

          <h2 className="text-2xl font-bold">¿Dónde debo pagar mis préstamos en línea seguros?</h2>
          <p>Te brindamos diversas opciones para realizar el pago de tus préstamos:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <b className="font-bold">Nuestro portal en línea:</b> Realiza tus pagos de forma segura y conveniente a través de nuestra plataforma en línea.
            </li>
            <li>
              <b className="font-bold">Pay Cash:</b> Realiza pagos en efectivo en establecimientos afiliados.
            </li>
            <li>
              <b className="font-bold">Oxxo Pay:</b> Utiliza la opción de pago en tiendas Oxxo.
            </li>
            <li>
              <b className="font-bold">Transferencia Bancaria:</b> Efectúa transferencias desde tu cuenta bancaria.
            </li>
          </ul>

          <p>En Vivus, te ofrecemos préstamos en línea seguros y confiables para que puedas cumplir tus metas y cubrir tus necesidades financieras de manera rápida y confiable. ¡Aplica por tu préstamo HOY mismo y empieza a hacer realidad tus proyectos!</p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>
        </div>
      </div>
    </Suspense>
  );
};

Page3.propTypes = {};
Page3.defaultValues = {};

export default Page3;
